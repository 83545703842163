import React from "react";

const Abi = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-xl text-gray-800 mb-2">Definition</p>
        <p className="text-md text-gray-600 mb-2">
          The ankle-brachial index test is a quick, non-invasive way to assess
          your risk for peripheral artery disease, a condition in which the
          arteries in your legs and ankles are narrowed. People with peripheral
          artery disease are at a higher risk of heart attack, stroke and poor
          circulation.
        </p>
        <p className="text-md text-gray-600 mb-12">
          The ankle-brachial index test compares your blood pressure measured at
          your ankle with your blood pressure measured at your arm. A low
          ankle-brachial index number can indicate narrowing or blockage of the
          arteries in your legs, leading to circulatory problems, heart disease
          or stroke.
        </p>

        <p className="text-xl text-gray-800 mb-2">Why it’s done</p>
        <p className="text-md text-gray-600 mb-2">
          Ask your doctor if you should have this test if you are age 50 or
          older and have any of these risk factors for peripheral artery
          disease:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>Smoking</li>
          <li>Diabetes</li>
          <li>Overweight (a body mass index of 25 or greater)</li>
          <li>High blood pressure</li>
          <li>High cholesterol</li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Risks</p>
        <p className="text-md text-gray-600 mb-12">
          The test may not adequately measure the ankle-brachial index if you
          have severe diabetes or calcified arteries with significant blockage.
          Instead, your doctor may need to read your blood pressure at your big
          toe to get an accurate index number if you have either of these
          conditions.
        </p>

        <p className="text-xl text-gray-800 mb-2">How you prepare</p>
        <p className="text-md text-gray-600 mb-12">
          Generally, you won’t need to follow any special instructions before
          your appointment to have an ankle-brachial index test performed.
        </p>

        <p className="text-xl text-gray-800 mb-2">What you can expect</p>
        <p className="text-md text-gray-600 mb-2">
          You lie on a table on your back, and your doctor measures your blood
          pressure in both your arms using an inflatable cuff. Then, your doctor
          measures the blood pressure in two positions in your left ankle using
          the inflatable cuff and a hand-held Doppler device that your doctor
          will press on your skin. The Doppler device lets your doctor hear your
          pulse in your ankle arteries after the cuff is deflated.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The procedure for performing an ankle-brachial index test may vary
          slightly, based on your doctor’s preference.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Having an ankle-brachial index test is painless and similar to getting
          your blood pressure taken in a routine visit to your doctor. You may
          feel some pressure on your arm or ankle when the cuff inflates to read
          your blood pressure.
        </p>

        <p className="text-xl text-gray-800 mb-2">Results</p>
        <p className="text-md text-gray-600 mb-2">
          When the ankle-brachial index test is complete, your doctor calculates
          your ankle-brachial index by dividing the higher of the two blood
          pressure measurements at your arms by the higher of the two blood
          pressure measurements at the arteries near your ankle. Based on the
          number your doctor calculates, your ankle-brachial index may indicate:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            No blockage (1.0 to 1.3). An ankle-brachial index number in this
            range suggests that you probably don’t have peripheral artery
            disease. But if you have certain risk factors, such as diabetes,
            smoking or a family history of PAD, tell your doctor so that he or
            she can continue to monitor your risk.
          </li>
          <li>
            Mild blockage (0.8 to 0.99). If your ankle-brachial index number is
            less than 1.0, you may have some narrowing of the arteries in your
            ankle. People with an ankle-brachial index of 0.9 or lower may have
            the beginnings of peripheral artery disease. Your doctor may then
            monitor your condition more closely.
          </li>
          <li>
            Moderate blockage (0.5 to .79). An ankle-brachial index number in
            this range indicates more significant blockage of your ankle
            arteries. You may have noticed some pain in your legs or buttocks
            when you exercise.
          </li>
          <li>
            Severe blockage (less than 0.5). If your ankle-brachial index number
            is in this range, your ankle arteries are significantly blocked and
            you may have pain in your legs even while resting. An ankle-brachial
            index of less than 0.5 suggests severe peripheral artery disease.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Abi;
