import React from "react";

const PageHeader = ({ text }) => {
  return (
    <div className="w-full flex justify-center items-center bg-sunstate-blue">
      <p className="text-white text-3xl py-16 font-serif text-center">{text}</p>
    </div>
  );
};

export default PageHeader;
