import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import Abi from "../../../components/resources/Abi";

const AbiAnkleBrachialIndexTest = () => {
  return (
    <Layout>
      <PageHeader text="ABI (Ankle-Brachial Index) Test" />
      <Abi />
      <Conditions />
    </Layout>
  );
};

export default AbiAnkleBrachialIndexTest;
